export const EditIcon = () => {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="#423BFE"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.29734 12.6379C1.87127 12.6379 1.47314 12.4912 1.18677 12.2188C0.823569 11.8765 0.648952 11.3597 0.711814 10.8009L0.970247 8.53788C1.01914 8.11181 1.27757 7.54605 1.57791 7.23873L7.31234 1.16908C8.7442 -0.346594 10.2389 -0.388502 11.7546 1.04336C13.2703 2.47521 13.3122 3.96993 11.8803 5.48561L6.1459 11.5553C5.85254 11.8696 5.30773 12.1629 4.88166 12.2328L2.6326 12.6169C2.51386 12.6239 2.40909 12.6379 2.29734 12.6379ZM9.55442 1.03637C9.0166 1.03637 8.54863 1.37164 8.07367 1.87453L2.33924 7.95123C2.19955 8.09791 2.0389 8.44715 2.01096 8.6497L1.75253 10.9127C1.72459 11.1432 1.78047 11.3317 1.90619 11.4505C2.03192 11.5692 2.2205 11.6111 2.451 11.5762L4.70006 11.1921C4.90262 11.1572 5.23788 10.9755 5.37758 10.8288L11.112 4.7592C11.9781 3.83723 12.2924 2.9851 11.0282 1.7977C10.4694 1.25988 9.98747 1.03637 9.55442 1.03637Z"
      />
      <path
        d="M10.5409 6.65223C10.527 6.65223 10.506 6.65223 10.492 6.65223C8.31282 6.4357 6.55967 4.78037 6.2244 2.61512C6.18249 2.32875 6.37806 2.06333 6.66444 2.01444C6.95081 1.97253 7.21623 2.1681 7.26512 2.45447C7.53054 4.14477 8.89953 5.44391 10.6038 5.61155C10.8902 5.63949 11.0997 5.89792 11.0718 6.18426C11.0368 6.44967 10.8063 6.65223 10.5409 6.65223Z"
      />
      <path
        d="M13.0963 14.8944H0.523851C0.237479 14.8944 0 14.6569 0 14.3705C0 14.0842 0.237479 13.8467 0.523851 13.8467H13.0963C13.3826 13.8467 13.6201 14.0842 13.6201 14.3705C13.6201 14.6569 13.3826 14.8944 13.0963 14.8944Z"
      />
    </svg>
  );
};
