import { warn } from "console";

export const queryKeys = {
  bulk: {
    getAll: "bulk/getAll",
    resend: "bulk/resend",
  },
  broadcast: {
    getAll: "broadcast/getAll",
    resend: "bulk/resend",
  },
  instance: {
    getAll: "instance/getAll",
  },
  contacts: {
    getAll: "contacts/getAll",
  },
  tags: {
    getAll: "tags/getAll",
  },
  media: {
    getAll: "media/getAll",
  },
  dashboard: {
    getStatusInfo: {
      getAll: "dashboard/getStatusInfo/getAll",
    },
    getCompanyInfo: {
      getAll: "dashboard/getCompanyInfo/getAll",
    },
    getAllMessagesInfo: {
      getAll: "dashboard/getAllMessagesInfo/getAll",
    },
    getCompanyInfoByFilter: {
      getAll: "dashboard/getCompanyInfoByFilter/getAll",
    },
    getAgentKpiReport: {
      getAll: "getAgentKpiReport/getAll",
    },
  },
  reports: {
    getReportsTypes: {
      getAll: "reports/getReportsTypes",
    },
    getReportsResults: {
      getAll: "reports/getReportsResults",
    },
    exportAll: 'reports/ExportAll',
  },
  agentsReports: {
    agentKpi: "agentReports/agentKpi",
    fetchAgentAvgKpiReport: "agentReports/fetchAgentAvgKpiReport",
    fetchAvgKpiReportByAgent: "agentReports/fetchAvgKpiReportByAgent",
  },
};
