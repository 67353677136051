export const talbeQueryKeys = {
  user: "new-dashboard/user",
  group: "new-dashboard/group",
  chatReason: "new-dashboard/chat-reason",
  channel: "new-dashboard/channel",
  tag: "new-dashboard/tag",
  bulk: "new-dashboard/bulk",
  broadcast: "new-dashboard/broadcast",
  aiAssistant: "new-dashboard/ai-assistant",
  store: "new-dashboard/store",
  complainType: "new-dashboard/complain-type",
  inquiry: "new-dashboard/inquiry-type",
  instance: "new-dashboard/instance",
  branch: "new-dashboard/branch",
  area: "new-dashboard/area",
  file: "new-dashboard/file",
  bot: "new-dashboard/bot",
  activity: "new-dashboard/activity-type",
  attendance: (id: string | null | undefined) => {
    return id != null
      ? `new-dashboard/attendance-activity?id=${id}`
      : id === undefined
        ? ""
        : "new-dashboard/attendance-activity";
  },
  contacts: (id: string) => `new-dashboard/bulk/contact/${id}`,
  bulkReport: (broadCastId: string) =>
    `new-dashboard/broadcast/report?broadCastId=${broadCastId}`,
  syncHistory: "sync/fetchSyncHistory",
  specialBroadcast: "new-dashboard/special/broadcast",
  doneChat: "contact/done-chats",
  company: "new-dashboard/company",
};
