import { EditIcon } from "../../icons/EditIcon";
import { DeleteIcon } from "../../icons/DeleteIcon";
import "./style.scss";
import { Popconfirm, PopconfirmProps, message } from "antd";
import { useTranslation } from "react-i18next";
import { useDelete, useRestore } from "../../Hooks/configration/useList";
import { RestartIcon } from "../../icons/restartIcon";

type Props = {
  queryKey: string;
  item: any;
  handleEdit: Function;
};

export const TableEditDelete = ({ handleEdit, queryKey, item }: Props) => {
  let { t } = useTranslation();

  const { mutateAsync: deleteItem } = useDelete(queryKey);
  const { mutateAsync: restoreItem } = useRestore(queryKey);

  const isDeleted = item.isDeleted;

  const confirm: PopconfirmProps["onConfirm"] = async (e) => {
    try {
      if (isDeleted) {
        await restoreItem(item._id);
      } else {
        await deleteItem(item._id);
      }
      message.success(
        t(
          `TableEditDelete.${isDeleted ? "msgRestoreSuccess" : "msgDeleteSuccess"}`
        )
      );
    } catch (error) {
      message.error(
        t(
          `TableEditDelete.${isDeleted ? "msgRestoreFailed" : "msgDeleteFailed"}`
        )
      );
    }
  };

  return (
    <p className="actions-cell">
      <span className="edit" onClick={() => handleEdit()}>
        <EditIcon /> {t("TableEditDelete.edit")}
      </span>

      <span className={isDeleted ? "restore" : "delete"}>
        <Popconfirm
          title={t(
            `TableEditDelete.${isDeleted ? "resotreConfirm" : "deleteConfirm"}`
          )}
          // description="Are you sure you want to delete?"
          onConfirm={confirm}
          okText={t("TableEditDelete.ok")}
          cancelText={t("TableEditDelete.no")}
        >
          {isDeleted ? (
            <RestartIcon style={{ transform: "scale(0.8)" }} />
          ) : (
            <DeleteIcon style={{ transform: "scale(0.8)" }} />
          )}

          <div className="delete-menu-item">
            {t(`TableEditDelete.${isDeleted ? "restore" : "delete"}`)}
          </div>
        </Popconfirm>
      </span>
    </p>
  );
};
