import { initializeApp } from 'firebase/app';
import { getMessaging } from 'firebase/messaging';
 
const firebaseConfig = {
    apiKey: "AIzaSyCVKQGEzcRnuk3oYvJtGy3qQQ0sO3iKISo",
    authDomain: "static-factor-389918.firebaseapp.com",
    projectId: "static-factor-389918",
    storageBucket: "static-factor-389918.appspot.com",
    messagingSenderId: "335100399427",
    appId: "1:335100399427:web:c4bb0781874a945a8269f6",
    measurementId: "G-Q9M1XSQYQ5" 
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export { messaging };
