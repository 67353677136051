import { SVGProps } from "react";

type Props = SVGProps<SVGSVGElement>;

export const RestartIcon = (props: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      {...props}
    >
      <path
        d="M17.1595 5.6887L16.364 4.90725C12.8493 1.45459 7.15075 1.45459 3.63604 4.90725C0.12132 8.35986 0.12132 13.9578 3.63604 17.4105C7.15075 20.8632 12.8493 20.8632 16.364 17.4105C18.4079 15.4026 19.2632 12.6693 18.9299 10.0543M17.1595 5.6887H12.3864M17.1595 5.6887V1"
        stroke="#423BFE"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
